import React, { Component } from 'react';
import appstoreesp from '../assets/img/badge_ESP_Appstore.png';
import playstoreesp from '../assets/img/badge_ESP_Google.png';
import appstoreeng from '../assets/img/badge_ENG_Appstore.png';
import playstoreeng from '../assets/img/badge_ENG_Google.png';
import { AndroidView, IOSView, BrowserView } from 'react-device-detect';
export class Header extends Component {
  render() {
    return (
      <div>
        <header>
          <div className={this.props.esp ? 'spanish' : 'd-none'}>
            <div className='texto'>
              <p>
                <b className='bolded'>APP</b>uesta por la comunicación eficiente
              </p>
            </div>
            <div className='imagen'>
              <IOSView>
                <a
                  href='https://apps.apple.com/mx/app/audi-m%C3%A9xico/id1488828365'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img id='appstore' src={appstoreesp} alt='Appstore' />
                </a>
              </IOSView>
              <AndroidView>
                <a
                  href='https://play.google.com/store/apps/details?id=mx.audi.audimexico'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img id='playstore' src={playstoreesp} alt='Playstore' />
                </a>
              </AndroidView>
              <BrowserView>
                <div id='stores-desk'>
                  <a
                    href='https://play.google.com/store/apps/details?id=mx.audi.audimexico'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img id='playstore' src={playstoreesp} alt='Playstore' />
                  </a>
                  <a
                    href='https://apps.apple.com/mx/app/audi-m%C3%A9xico/id1488828365'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img id='appstore' src={appstoreesp} alt='Appstore' />
                  </a>
                </div>
              </BrowserView>
            </div>
          </div>
          <div className={this.props.eng ? 'english' : 'd-none'}>
            <div className='texto'>
              <p>
                <b className='bolded'>APP</b>roach to effective communication
              </p>
            </div>
            <div className='imagen'>
              <IOSView>
                <a
                  href='https://apps.apple.com/mx/app/audi-m%C3%A9xico/id1488828365'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img id='appstore' src={appstoreeng} alt='Appstore' />
                </a>
              </IOSView>
              <AndroidView>
                <a
                  href='https://play.google.com/store/apps/details?id=mx.audi.audimexico'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img id='playstore' src={playstoreeng} alt='Playstore' />
                </a>
              </AndroidView>
              <BrowserView>
                <div className='stores-desk'>
                  <a
                    href='https://play.google.com/store/apps/details?id=mx.audi.audimexico'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img id='playstore' src={playstoreeng} alt='Playstore' />
                  </a>
                  <a
                    href='https://apps.apple.com/mx/app/audi-m%C3%A9xico/id1488828365'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img id='appstore' src={appstoreeng} alt='Appstore' />
                  </a>
                </div>
              </BrowserView>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
