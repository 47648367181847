import React, { Component } from "react";
import Navbar from "./reutilizables/Navbar/Navbar";
import Footer from "../layout/Footer";
import Body from "../layout/Body";
import { Link } from "react-router-dom";

import backRed from "../assets/img/arrow_back_red.png";
export class Privacy extends Component {
  constructor() {
    super();
    this.state = {
      esp: true,
      eng: false
    };
  }
  componentDidMount() {
    document.addEventListener("scroll", this.listenScrollEvent);
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    const eng = localStorage.getItem("eng");

    if (eng === "true") {
      this.setState({
        esp: false,
        eng: true
      });
    }
  }
  changeLanguage = e => {
    const value = e.target.value;
    if (value === "esp") {
      localStorage.setItem("eng", false);
      this.setState({
        esp: true,
        eng: false
      });
    } else {
      localStorage.setItem("eng", true);
      this.setState({
        esp: false,
        eng: true
      });
    }
  };
  componentWillUnmount() {
    document.removeEventListener("scroll", this.listenScrollEvent);
  }
  render() {
    return (
      <div>
        <Navbar
          esp={this.state.esp}
          eng={this.state.eng}
          changeLanguage={this.changeLanguage}
        />
        <div className={this.state.esp ? "privacy esp" : "d-none"}>
          <div className="back">
            <Link to="/">
              <img src={backRed} alt="Back" />
            </Link>
          </div>
          <div className="text-privacy">
            <h4>Aviso de privacidad</h4>
            <h5>AUDI MÉXICO S.A. de C.V.</h5>
            <p>
              AUDI MÉXICO S.A. de C.V. (en adelante Audi México) con domicilio
              en Boulevard Q5 No. 1, San José Chiapa, Puebla, C.P. 75012, México
              hace de su conocimiento que los datos personales, datos personales
              sensibles, datos patrimoniales o financieros que nos proporcione,
              serán protegidos conforme a lo establecido en la Ley Federal de
              Protección de Datos en Posesión de los Particulares, su
              Reglamento, lineamientos, parámetros y demás disposiciones
              aplicables (en adelante la “Ley”)
            </p>
            <p>
              Audi México cuenta con un departamento de Protección de Datos
              Personales (M/GG) el cual puede ser contactado vía correo
              electrónico a la dirección{" "}
              <a href="mailto:datospersonales@audi.mx">
                datospersonales@audi.mx
              </a>{" "}
              o vía presencial en la oficina G214 del Edificio Spine en el
              domicilio de la misma.
            </p>
            <p>
              En este sentido, hacemos de su conocimiento que sus datos serán
              tratados y resguardados con base en los principios de licitud,
              calidad, consentimiento, información, finalidad, lealtad,
              proporcionalidad y responsabilidad, consagrados en la Ley.
            </p>
            <p className="underline">Los datos que solicitamos son:</p>
            <ul>
              <li>
                <strong>Datos de identificación y contacto:</strong> Nombre,
                apellidos, fecha de nacimiento, género, estado civil, número de
                teléfono y correo electrónico;
              </li>
              <li>
                <strong>Datos Laborales:</strong> Número de personal, clave de
                función, unidad organizativa, fecha de contratación, estatus
                ocupacional, área de personal, grupo de trabajo, horario de
                trabajo, plan horario periodo, suplencia y ausentismos;
              </li>
              <li>
                <strong>Datos Familiares:</strong> número de hijos.
              </li>
              <li>
                <strong>Datos de Redes y/o Autenticación:</strong> Usuario y
                contraseña
              </li>
              <li>
                <strong>Datos de navegación:</strong> Monitoreo de sesiones,
                geo-localización y seguimiento de actividades.
              </li>
              <li>
                <strong>Datos Sensibles:</strong> Fotografías
              </li>
            </ul>
            <p>
              Todos los datos personales y datos sensibles previamente
              mencionados, estarán protegidos mediante las medidas de seguridad
              físicas, técnicas y administrativas que Audi México ha adoptado
              previamente, a efecto de evitar cualquier vulneración a su
              información personal. Todos los datos personales serán obtenidos,
              única y exclusivamente a través del uso que usted mismo haga del
              aplicativo móvil.
            </p>
            <p className="underline">Finalidades:</p>
            <p>
              Los datos personales, datos personales sensibles y datos
              patrimoniales o financieros que recabamos de Usted, los
              utilizaremos para las siguientes finalidades primarias:
            </p>
            <ol>
              <li>Para identificar a la persona;</li>
              <li>Creación y registro de usuarios</li>
              <li>Generación, modificación y resguardo de contraseñas</li>
              <li>Permitir acceso a contenido del aplicativo</li>
              <li>
                Brindar información a los usuarios relacionada con el centro de
                trabajo
              </li>
              <li>
                Brindar a los usuarios herramientas que faciliten la
                comunicación
              </li>
              <li>
                Brindar información en tiempo real a través del posicionamiento
                geográfico (limitado a área geográfica y no posicionamiento
                exacto de los usuarios)
              </li>
              <li>
                Configuración del aplicativo móvil con base en las preferencias
                establecidas por el usuario
              </li>
              <li>Envío de notificaciones</li>
              <li>Creación de favoritos</li>
              <li>Personalización de escritorio en aplicativo móvil</li>
              <li>
                Envío de notificaciones, referentes a la cuenta de los usuarios,
                por correo electrónico
              </li>
            </ol>
            <p className="underline">Transferencia de datos personales</p>
            <p>
              Audi México, en diversas fases y para distintas finalidades del
              tratamiento de sus datos personales previamente identificados,
              precisa solicitar la intervención de Encargados, quienes serán
              personas físicas o morales que lleven a cabo dicho tratamiento en
              nombre y cuenta de Audi México, asegurándose éste último que dicho
              tratamiento sea realice en apego a lo establecido en el presente
              Aviso de Privacidad. Derivado de lo anterior y en atención a lo
              dispuesto por el artículo 53 del Reglamento de la Ley, se hace de
              su conocimiento que Audi México no precisa obtener su
              consentimiento para llevar a cabo dichas acciones ya que las
              mismas configuran una Remisión de datos personales, en la cual el
              responsable por el manejo y cuidado de sus datos personales,
              seguirá siendo en todo momento Audi México.
            </p>
            <p>
              No obstante, a efecto de transparentar el tratamiento de datos
              personales, hacemos de su conocimiento que en dicho tratamiento
              intervendrán los siguientes Encargados:
            </p>
            <ul>
              <li>
                Empresas de Prestación de servicios de computo en la nube. Con
                la finalidad de llevar a cabo el almacenamiento y procesamiento
                de datos personales;
              </li>
              <li>
                Empresas de prestación de servicios de mantenimiento de
                software. Con la finalidad de dar mantenimiento a los
                dispositivos y programas que almacenan y procesan los datos
                personales, a fin de evitar vulneraciones a la seguridad de los
                mismos.
              </li>
            </ul>
            <p className="underline">
              Medios para Limitar el Uso o Divulgación de sus Datos:
            </p>
            <p>
              Hacemos de su conocimiento que sus datos personales, datos
              personales sensibles y datos personales patrimoniales o
              financieros serán resguardados bajo medidas de seguridad
              administrativas, físicas y técnicas, las cuales han sido
              implementadas, en términos de la Ley, con el objeto de proteger
              sus datos contra cualquier daño, pérdida, alteración, destrucción
              o el uso, acceso y tratamiento no autorizados, así como también
              acotar en la medida posible cualquier riesgo que pudiera llegar a
              presentarse.
            </p>
            <p className="underline">
              Mecanismos y procedimientos para revocación de consentimiento
            </p>
            <p>
              Hacemos de su conocimiento que en todo momento usted podrás
              revocar el consentimiento al tratamiento de sus datos personales
              que, en su caso, haya otorgado con anterioridad. Sin embargo, es
              importante mencionar que, en caso, de revocar su consentimiento
              Audi México se verá imposibilitado a seguir llevando a cabo el
              tratamiento de sus datos personales y en consecuencia su cuenta en
              el aplicativo móvil será cancelada y posteriormente eliminada de
              nuestras bases de datos, impidiéndole el acceso al mismo.
            </p>
            <p>
              Para revocar su consentimiento, es indispensable hacer clic en el
              siguiente enlace revocación de consentimiento y proporcionar los
              datos de redes o autenticación que se solicitan (usuario y
              contraseña).
            </p>
            <p>
              Una vez que hayamos recibido su solicitud, la misma será procesada
              y su cuenta será dada de baja en un plazo no mayor a 20 días
              naturales, previa notificación a su correo electrónico.
            </p>
            <p className="underline">Ejercicio de Derechos ARCO</p>
            <p>
              Para ejercitar sus derechos de acceso, rectificación, cancelación
              y oposición (Derechos ARCO), el Titular deberá enviar una
              solicitud de ejercicio de sus Derechos ARCO o de revocación al
              siguiente correo electrónico;{" "}
              <a href="mailto:datospersonales@audi.mx">
                datospersonales@audi.mx
              </a>
              , la cual, en atención a lo dispuesto por la Ley federal de
              Protección de Datos Personales en Posesión de los Particulares y
              su Reglamento, deberá contener al menos la siguiente información:
            </p>
            <ul>
              <li>
                Nombre completo y medio para recibir notificaciones (correo
                electrónico).
              </li>
              <li>Copia de identificación oficial.</li>
              <li>
                En caso de ser ejercido por representante legal: Copia de
                identificación del titular, identificación del Representante e
                Instrumento con que acredite personalidad.
              </li>
              <li>Derecho ARCO que desea ejercer</li>
              <li>
                Datos personales sobre los cuales desea ejercer sus derechos
                ARCO.
              </li>
              <li>
                Cualquier otra información que nos ayude a localizar sus datos
                personales (sitio de internet donde proporcionó sus datos
                personales).
              </li>
            </ul>
            <p className="underline">
              Uso de cookies y tecnologías similares o análogas
            </p>
            <p>
              Hacemos de su conocimiento, que Audi México utiliza cookies, web
              beacons y otras tecnologías a través de las cuales es posible
              monitorear su comportamiento como usuario del aplicativo móvil
              para brindarle una mejor experiencia de navegación basado en sus
              preferencias.
            </p>
            <p>
              Los datos personales que obtenemos a través de estas tecnologías
              son los siguientes
            </p>
            <ul>
              <li>Monitoreo de sesiones;</li>
              <li>Geo-localización; y</li>
              <li>Seguimiento de actividades</li>
            </ul>
            <p>
              La geo-localización podrá deshbailitarse por los usuarios,
              siguiendo los siguientes pasos:
            </p>
            <p className="underline">
              Entrar al sistema de configuración de su dispositivo y desactivar
              el permiso del aplicativo para utilizar el GPS de su dispositivo.
            </p>
            <p className="underline">
              Respecto al monitoreo de sesiones, así como el seguimiento de
              actividades, hacemos de su conocimiento que son datos utilizados
              para cumplir con las finalidades primarias del tratamiento de sus
              datos personales, por lo cual, en caso de no estar de acuerdo en
              que se obtenga dicha información, Audi México se verá
              imposibilitado de brindarle los servicios del aplicativo móvil,
              por lo cual deberá proceder a la revocación de su consentimiento o
              al ejercicio de su derecho de cancelación en atención a lo
              dispuesto por los apartados anteriores del presente Aviso de
              Privacidad.
            </p>
          </div>
        </div>
        <div className={this.state.eng ? "privacy eng" : "d-none"}>
          <div className="back">
            <Link to="/">
              <img src={backRed} alt="Back" />
            </Link>
          </div>
          <div className="text-privacy">
            <h4>Privacy</h4>
            <h5>AUDI MÉXICO S.A. de C.V.</h5>
            <p>
              AUDI MÉXICO S.A. de C.V. (en adelante Audi México) con domicilio
              en Boulevard Q5 No. 1, San José Chiapa, Puebla, C.P. 75012, México
              hace de su conocimiento que los datos personales, datos personales
              sensibles, datos patrimoniales o financieros que nos proporcione,
              serán protegidos conforme a lo establecido en la Ley Federal de
              Protección de Datos en Posesión de los Particulares, su
              Reglamento, lineamientos, parámetros y demás disposiciones
              aplicables (en adelante la “Ley”)
            </p>
            <p>
              Audi México cuenta con un departamento de Protección de Datos
              Personales (M/GG) el cual puede ser contactado vía correo
              electrónico a la dirección{" "}
              <a href="mailto:datospersonales@audi.mx">
                datospersonales@audi.mx
              </a>{" "}
              o vía presencial en la oficina G214 del Edificio Spine en el
              domicilio de la misma.
            </p>
            <p>
              En este sentido, hacemos de su conocimiento que sus datos serán
              tratados y resguardados con base en los principios de licitud,
              calidad, consentimiento, información, finalidad, lealtad,
              proporcionalidad y responsabilidad, consagrados en la Ley.
            </p>
            <p className="underline">Los datos que solicitamos son:</p>
            <ul>
              <li>
                <strong>Datos de identificación y contacto:</strong> Nombre,
                apellidos, fecha de nacimiento, género, estado civil, número de
                teléfono y correo electrónico;
              </li>
              <li>
                <strong>Datos Laborales:</strong> Número de personal, clave de
                función, unidad organizativa, fecha de contratación, estatus
                ocupacional, área de personal, grupo de trabajo, horario de
                trabajo, plan horario periodo, suplencia y ausentismos;
              </li>
              <li>
                <strong>Datos Familiares:</strong> número de hijos.
              </li>
              <li>
                <strong>Datos de Redes y/o Autenticación:</strong> Usuario y
                contraseña
              </li>
              <li>
                <strong>Datos de navegación:</strong> Monitoreo de sesiones,
                geo-localización y seguimiento de actividades.
              </li>
              <li>
                <strong>Datos Sensibles:</strong> Fotografías
              </li>
            </ul>
            <p>
              Todos los datos personales y datos sensibles previamente
              mencionados, estarán protegidos mediante las medidas de seguridad
              físicas, técnicas y administrativas que Audi México ha adoptado
              previamente, a efecto de evitar cualquier vulneración a su
              información personal. Todos los datos personales serán obtenidos,
              única y exclusivamente a través del uso que usted mismo haga del
              aplicativo móvil.
            </p>
            <p className="underline">Finalidades:</p>
            <p>
              Los datos personales, datos personales sensibles y datos
              patrimoniales o financieros que recabamos de Usted, los
              utilizaremos para las siguientes finalidades primarias:
            </p>
            <ol>
              <li>Para identificar a la persona;</li>
              <li>Creación y registro de usuarios</li>
              <li>Generación, modificación y resguardo de contraseñas</li>
              <li>Permitir acceso a contenido del aplicativo</li>
              <li>
                Brindar información a los usuarios relacionada con el centro de
                trabajo
              </li>
              <li>
                Brindar a los usuarios herramientas que faciliten la
                comunicación
              </li>
              <li>
                Brindar información en tiempo real a través del posicionamiento
                geográfico (limitado a área geográfica y no posicionamiento
                exacto de los usuarios)
              </li>
              <li>
                Configuración del aplicativo móvil con base en las preferencias
                establecidas por el usuario
              </li>
              <li>Envío de notificaciones</li>
              <li>Creación de favoritos</li>
              <li>Personalización de escritorio en aplicativo móvil</li>
              <li>
                Envío de notificaciones, referentes a la cuenta de los usuarios,
                por correo electrónico
              </li>
            </ol>
            <p className="underline">Transferencia de datos personales</p>
            <p>
              Audi México, en diversas fases y para distintas finalidades del
              tratamiento de sus datos personales previamente identificados,
              precisa solicitar la intervención de Encargados, quienes serán
              personas físicas o morales que lleven a cabo dicho tratamiento en
              nombre y cuenta de Audi México, asegurándose éste último que dicho
              tratamiento sea realice en apego a lo establecido en el presente
              Aviso de Privacidad. Derivado de lo anterior y en atención a lo
              dispuesto por el artículo 53 del Reglamento de la Ley, se hace de
              su conocimiento que Audi México no precisa obtener su
              consentimiento para llevar a cabo dichas acciones ya que las
              mismas configuran una Remisión de datos personales, en la cual el
              responsable por el manejo y cuidado de sus datos personales,
              seguirá siendo en todo momento Audi México.
            </p>
            <p>
              No obstante, a efecto de transparentar el tratamiento de datos
              personales, hacemos de su conocimiento que en dicho tratamiento
              intervendrán los siguientes Encargados:
            </p>
            <ul>
              <li>
                Empresas de Prestación de servicios de computo en la nube. Con
                la finalidad de llevar a cabo el almacenamiento y procesamiento
                de datos personales;
              </li>
              <li>
                Empresas de prestación de servicios de mantenimiento de
                software. Con la finalidad de dar mantenimiento a los
                dispositivos y programas que almacenan y procesan los datos
                personales, a fin de evitar vulneraciones a la seguridad de los
                mismos.
              </li>
            </ul>
            <p className="underline">
              Medios para Limitar el Uso o Divulgación de sus Datos:
            </p>
            <p>
              Hacemos de su conocimiento que sus datos personales, datos
              personales sensibles y datos personales patrimoniales o
              financieros serán resguardados bajo medidas de seguridad
              administrativas, físicas y técnicas, las cuales han sido
              implementadas, en términos de la Ley, con el objeto de proteger
              sus datos contra cualquier daño, pérdida, alteración, destrucción
              o el uso, acceso y tratamiento no autorizados, así como también
              acotar en la medida posible cualquier riesgo que pudiera llegar a
              presentarse.
            </p>
            <p className="underline">
              Mecanismos y procedimientos para revocación de consentimiento
            </p>
            <p>
              Hacemos de su conocimiento que en todo momento usted podrás
              revocar el consentimiento al tratamiento de sus datos personales
              que, en su caso, haya otorgado con anterioridad. Sin embargo, es
              importante mencionar que, en caso, de revocar su consentimiento
              Audi México se verá imposibilitado a seguir llevando a cabo el
              tratamiento de sus datos personales y en consecuencia su cuenta en
              el aplicativo móvil será cancelada y posteriormente eliminada de
              nuestras bases de datos, impidiéndole el acceso al mismo.
            </p>
            <p>
              Para revocar su consentimiento, es indispensable hacer clic en el
              siguiente enlace revocación de consentimiento y proporcionar los
              datos de redes o autenticación que se solicitan (usuario y
              contraseña).
            </p>
            <p>
              Una vez que hayamos recibido su solicitud, la misma será procesada
              y su cuenta será dada de baja en un plazo no mayor a 20 días
              naturales, previa notificación a su correo electrónico.
            </p>
            <p className="underline">Ejercicio de Derechos ARCO</p>
            <p>
              Para ejercitar sus derechos de acceso, rectificación, cancelación
              y oposición (Derechos ARCO), el Titular deberá enviar una
              solicitud de ejercicio de sus Derechos ARCO o de revocación al
              siguiente correo electrónico;{" "}
              <a href="mailto:datospersonales@audi.mx">
                datospersonales@audi.mx
              </a>
              , la cual, en atención a lo dispuesto por la Ley federal de
              Protección de Datos Personales en Posesión de los Particulares y
              su Reglamento, deberá contener al menos la siguiente información:
            </p>
            <ul>
              <li>
                Nombre completo y medio para recibir notificaciones (correo
                electrónico).
              </li>
              <li>Copia de identificación oficial.</li>
              <li>
                En caso de ser ejercido por representante legal: Copia de
                identificación del titular, identificación del Representante e
                Instrumento con que acredite personalidad.
              </li>
              <li>Derecho ARCO que desea ejercer</li>
              <li>
                Datos personales sobre los cuales desea ejercer sus derechos
                ARCO.
              </li>
              <li>
                Cualquier otra información que nos ayude a localizar sus datos
                personales (sitio de internet donde proporcionó sus datos
                personales).
              </li>
            </ul>
            <p className="underline">
              Uso de cookies y tecnologías similares o análogas
            </p>
            <p>
              Hacemos de su conocimiento, que Audi México utiliza cookies, web
              beacons y otras tecnologías a través de las cuales es posible
              monitorear su comportamiento como usuario del aplicativo móvil
              para brindarle una mejor experiencia de navegación basado en sus
              preferencias.
            </p>
            <p>
              Los datos personales que obtenemos a través de estas tecnologías
              son los siguientes
            </p>
            <ul>
              <li>Monitoreo de sesiones;</li>
              <li>Geo-localización; y</li>
              <li>Seguimiento de actividades</li>
            </ul>
            <p>
              La geo-localización podrá deshbailitarse por los usuarios,
              siguiendo los siguientes pasos:
            </p>
            <p className="underline">
              Entrar al sistema de configuración de su dispositivo y desactivar
              el permiso del aplicativo para utilizar el GPS de su dispositivo.
            </p>
            <p className="underline">
              Respecto al monitoreo de sesiones, así como el seguimiento de
              actividades, hacemos de su conocimiento que son datos utilizados
              para cumplir con las finalidades primarias del tratamiento de sus
              datos personales, por lo cual, en caso de no estar de acuerdo en
              que se obtenga dicha información, Audi México se verá
              imposibilitado de brindarle los servicios del aplicativo móvil,
              por lo cual deberá proceder a la revocación de su consentimiento o
              al ejercicio de su derecho de cancelación en atención a lo
              dispuesto por los apartados anteriores del presente Aviso de
              Privacidad.
            </p>
          </div>
        </div>
        <div className="borde"></div>

        <Body esp={this.state.esp} eng={this.state.eng} />
        <Footer
          esp={this.state.esp}
          eng={this.state.eng}
          changeLanguage={this.changeLanguage}
        />
      </div>
    );
  }
}

export default Privacy;
