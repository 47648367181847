import React from "react";
import Home from "./components/Home";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Conditions from "./components/Conditions";
import Privacy from "./components/Privacy";
import Questions from "./components/Questions";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/terms" component={Conditions} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/faq" component={Questions} />
      </Switch>
    </Router>
  );
}

export default App;
