import React, { Component } from "react";
import Navbar from "./reutilizables/Navbar/Navbar";
import Footer from "../layout/Footer";
import Body from "../layout/Body";
import { Link } from "react-router-dom";

import backRed from "../assets/img/arrow_back_red.png";
export class Conditions extends Component {
  constructor() {
    super();
    this.state = {
      esp: true,
      eng: false
    };
  }
  componentDidMount() {
    document.addEventListener("scroll", this.listenScrollEvent);
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    const eng = localStorage.getItem("eng");

    if (eng === "true") {
      this.setState({
        esp: false,
        eng: true
      });
    }
  }
  changeLanguage = e => {
    const value = e.target.value;
    if (value === "esp") {
      localStorage.setItem("eng", false);
      this.setState({
        esp: true,
        eng: false
      });
    } else {
      localStorage.setItem("eng", true);
      this.setState({
        esp: false,
        eng: true
      });
    }
  };
  componentWillUnmount() {
    document.removeEventListener("scroll", this.listenScrollEvent);
  }
  render() {
    return (
      <div>
        <Navbar
          esp={this.state.esp}
          eng={this.state.eng}
          changeLanguage={this.changeLanguage}
        />
        <div className={this.state.esp ? "terms esp" : "d-none"}>
          <div className="back">
            <Link to="/">
              <img src={backRed} alt="Back" />
            </Link>
          </div>
          <div className="text-terms">
            <h4>Términos y Condiciones de uso para “App Audi México”</h4>
            <p>
              Por favor lea los siguientes términos y condiciones que son
              aplicables al uso de la aplicación denominada Audi México (En lo
              sucesivo la App), que son aplicables para los empleados de AUDI
              MÉXICO S.A. de C.V. (en lo sucesivo Audi México). Al entrar y
              utilizar esta Aplicación móvil, Usted (el "Usuario") reconoce
              aceptar las condiciones y los términos aquí contenidos y declara
              expresamente la aceptación de lo siguiente:
            </p>
            <ol>
              <li>Estipulaciones Generales</li>
            </ol>
            <p>
              A través de la presente App, Audi México pone a disposición de sus
              empleados una herramienta de generación de contenido a través de
              los diversos módulos que la conforman, por lo que la información
              contenida en esta App, tendrá en todo momento un carácter
              meramente informativo y de consulta, por lo que los contenidos y/o
              criterios resultado de la interacción del Usuario con la App son
              exclusivamente para facilitar y fomentar la comunicación
              empresarial de Audi México con sus empleados.
            </p>
            <p>
              La información contenida en la App no podrá considerarse ni
              utilizarse como una prueba ante autoridades ni dársele un fin
              distinto al aquí estipulado.
            </p>
            <p>
              El registro por parte del Usuario de la app, es completamente
              voluntario y se encuentra restringido a aquellas personas que
              tienen carácter de empleados de Audi México, por lo que será
              negado el registro a toda persona que no cumpla con dicha
              condición.
            </p>
            <p>
              El Usuario deberá de facilitar la información requerida por la App
              a efecto de lograr su registro y proceder a la interacción con los
              distintos módulos que la conforman, para lo cual, dicha
              información deberá de ser real y coincidir con la información que
              se utilice en su carácter de empleados con Audi México.
            </p>
            <p>
              Durante el proceso de registro y la interacción con la App, el
              Usuario proporcionará Datos Personales a Audi México, por lo que
              el tratamiento de dichos datos personales se sujetará a lo
              establecido al efecto por el “Aviso de Privacidad para Aplicativo
              Móvil de Audi México”, el cual ha sido puesto a su disposición con
              anterioridad. Tal y como se menciona en dicho Aviso de Privacidad,
              es posible que en algunos módulos de la App se requiera la
              geo-localización de su dispositivo para el debido funcionamiento
              del correspondiente módulo; en este sentido para una
              geo-localización más adecuada, el Usuario deberá activar y
              autorizar los servicios de ubicación, que utilizan información
              como, por ejemplo, señales GPS, sensores de dispositivo, puntos de
              acceso Wi-Fi, identificadores de redes móviles, entre otros; que
              sirven para estimar la ubicación precisa. Si así lo desea, el
              Usuario puede, posteriormente, desactiva los servicios de
              geo-localización requeridos, teniendo en cuenta las limitaciones
              de uso de la App que ello podría implicar.
            </p>
            <ol start="2">
              <li>Obligaciones Generales de los Usuarios de la App</li>
            </ol>
            <p>
              Durante el uso de la App, el Usuario deberá siempre observar una
              conducta adecuada y de respeto, principalmente con otros Usuarios
              en aquellos módulos en los que se permita al Usuario hacer
              comentarios o publicaciones, debiendo de igual forma, en todo
              momento, abstenerse de publicar material que, enunciativa más no
              limitativamente, sea difamatorio, obsceno, pornográfico, ofensivo,
              profano, amenazante y/o violatorio de cualquier Ley, norma o
              reglamento aplicable, así como cualquier material que constituya o
              aliente cualquier conducta que pudiera considerarse como actividad
              criminar y/o provocar actos criminales o contrarios a la ley y/o
              que infrinja derechos de propiedad industrial o intelectual, así
              como derechos de autor.
            </p>
            <p>
              Los Usuarios deberán en todo momento proteger los intereses de
              Audi México, así como de cualquier otra empresa integrando del
              Grupo Mundial Volkswagen por lo que respecta a la confidencialidad
              de la información, en la inteligencia de lo anterior; el Usuario
              en caso de duda respecto a la clasificación de confidencialidad de
              la información que se encuentre disponible en la App, deberá de
              consultar a su superior jerárquico, a fin de resolver dicha duda y
              velar por la confidencialidad de la información.
            </p>
            <p>
              De igual forma, durante la interacción con la App, el Usuario
              deberá abstenerse de realizar cualesquiera de los siguientes
              actos:
            </p>
            <ul>
              <li>
                Publicar o enviar, de manera enunciativa más no limitativa,
                cualquier material que sea difamatorio, obsceno, pornográfico,
                ofensivo, profano, amenazante y/o violatorio de cualquier Ley,
                norma o reglamento aplicable, así como cualquier material que
                constituya o aliente cualquier conducta que pudiera considerarse
                como actividad criminar y/o provocar actos criminales o
                contrarios a la ley y/o que infrinja derechos de propiedad
                industrial o intelectual, así como derechos de autor, en
                detrimento de Audi México y/o cualquier otra empresa integrante
                del Grupo Mundial Volkswagen;
              </li>
              <li>
                Actuar en nombre y por cuenta de terceros o incluso en su
                carácter de representante de Audi México;
              </li>
              <li>
                Infringir derechos de Terceros, tal es el caso como la
                publicación de textos, imágenes, audios o videos propiedad de
                terceros, sin que se cuente con una autorización legal al
                respecto;
              </li>
              <li>
                Infringir cualquier lineamiento o política interno de Audi
                México por lo que respecta, principalmente, a la
                confidencialidad de la información y la protección de datos
                personales;
              </li>
              <li>
                Entregar sus datos personales, así como datos de registro y
                acceso (usuario y contraseña) a terceras personas que puedan
                hacer mal uso de la App;
              </li>
              <li>
                Realizar cualquier publicación de manera anónima bajo una
                identidad de usuario diferente al que le fue asignado en su
                carácter de empleado de Audi México;
              </li>
              <li>
                Transferir, divulgar, hacer accesible o de cualquier otra forma
                trasparentar a terceras personas, la información y contenido
                divulgado en la App;
              </li>
              <li>
                Realizar publicaciones en idiomas distintos a los autorizados
                por Audi México (Español, Alemán y/o Inglés);
              </li>
              <li>
                Utilizar la App como plataforma de publicidad, ya sea para la
                oferta de bienes y/o servicios adicionales y que no tengan
                relación directa con las funciones que desempeña al interior de
                Audi México en su carácter de empleado
              </li>
              <li>
                Divulgar información confidencial o personal de terceras
                personas que puedan poner en riesgo su integridad personal,
                física o moral;
              </li>
              <li>
                La utilización de tecnologías de la información para a
                generación de comentarios, respuestas o publicaciones
                automatizadas
              </li>
            </ul>
            <p>
              En adición a las obligaciones generales aquí establecidas, los
              Usuarios, al tener el carácter de empleados, deberán de cumplir
              con los reglamentos y lineamientos internos de Audi México.
            </p>
            <p>
              El Usuario entiende y acepta que la interacción en esta App, no
              deberá obstaculizar las obligaciones inherentes al desempeño de
              sus funciones al interior de Audi México y que el uso de dicha App
              en horarios laborales, puede ser causa justificada para el
              establecimiento de sanciones por parte de Audi México en atención
              a las disposiciones internas aplicables.
            </p>
            <ol start="3">
              <li>
                Obligaciones de confidencialidad y de protección de datos
                personales
              </li>
            </ol>
            <p>
              Durante la interacción con la App, los Usuarios entregarán y, a su
              vez, podrán tener acceso a datos de carácter personal que son
              considerados como confidenciales de conformidad con la Ley Federal
              de Protección de Datos Personales en Posesión de los Particulares,
              mismos que corresponden a la información personal de los empleados
              de Audi México y/o de cualquiera de las empresas integrantes del
              Grupo Mundial Volkswagen.
            </p>
            <p>
              Esta expresamente prohibido para los Usuarios, la divulgación,
              revelación, comunicación, transmisión, grabación, copiado o en
              cualquier forma reproducir sin autorización expresa y por escrito
              de Audi México, los datos personales a los que los Usuarios tengan
              acceso por medio de esta App.
            </p>
            <p>
              Los Usuarios que hagan uso de la aplicación, tienen conocimiento y
              otorgan su consentimiento, mediante la aceptación de los presentes
              términos y condiciones, a favor de Audi México, a efecto de llevar
              a cabo el tratamiento de sus datos personales para las finalidades
              que han sido previamente identificadas en el Aviso de Privacidad
              para Aplicativo Móvil de Audi México, mismo que fue puesto a su
              disposición con anterioridad.
            </p>
            <p>
              De igual forma, los Usuarios reconocen y aceptan, mediante la
              aceptación de los presentes términos y condiciones, que la
              violación o el incumplimiento a las disposiciones en materia de
              protección de datos personales, podrá ser sancionado por la Ley
              Federal de Protección de Datos Personales en Posesión de los
              Particulares, así como también, podrá ser sancionado de forma
              interna por Audi México, de conformidad con los lineamientos y
              reglamentos internos aplicables.
            </p>
            <ol start="4">
              <li>
                Autorización de gestión de datos laborales a través de la App
              </li>
            </ol>
            <p>
              El Usuario reconoce, acepta y autoriza que sus datos relacionados
              a procesos de Recursos Humanos, los cuales han sido recabados y
              tratados a lo largo de la relación laboral que mantiene con Audi
              México, se encuentren en la App como una forma fácil de acceso a
              dicha información por lo que autoriza a Audi México a que la
              información referida sea accesible a través de la App, asimismo
              reconoce que el tratamiento de dichos datos está sujeto al Aviso
              de Privacidad para empleados, el cual ha sido puesto a su
              disposición con anterioridad por Audi México.
            </p>
            <ol start="5">
              <li>
                Infracciones y sanciones a las condiciones de uso estipuladas.
              </li>
            </ol>
            <p>
              Audi México, a través del Administrador de la App, se reserva el
              derecho de suspender temporal o permanentemente a cualquier
              Usuario que infrinja las disposiciones de los presentes términos y
              condiciones, relativas al uso de la App, siempre y cuando existan
              pruebas contundentes o la sospecha razonable y fundada de la
              infracción cometida por el Usuarios.
            </p>
            <p>
              Independientemente de la conducta realizada por el Usuario, Audi
              México tendrá derecho a bloquear el acceso a toda o parte de la
              información y/o publicaciones de la App, en caso de que se reciba
              información que indique la presencia de cualquier acto o indicio
              de transgresión a los presentes términos y condiciones,
              independientemente de la sanciona administrativa a la que sea
              acreedor el Usuarios transgresor. De igual forma, en cualquier
              momento, Audi México estará facultado para censurar, eliminar,
              bloquear o incluso denunciar ante las autoridades competentes,
              cualquier tipo de comentario o publicación realizada por los
              Usuarios en la App.
            </p>
            <p>
              En caso de que la App de Audi México proporcione acceso a otros
              sitios web a través de enlaces, Audi México no acepta el contenido
              de terceros como propio y no asume responsabilidad por dicho
              contenido, así como por la veracidad o la exactitud de este. La
              única responsabilidad de Audi México respecto a enlaces que
              proporcionen información con contenido ilegal o no verificado,
              será eliminar el enlace, tan pronto tenga conocimiento de esto.
            </p>
            <p>
              Cada Usuario es responsable respecto a la información que otorgue
              mediante la interacción en la App, así como respecto a sus
              comentarios, publicaciones y comportamiento en la App, liberando
              en todo momento a Audi México de cualquier responsabilidad que
              pudiera llegar a surgir ante terceros por el incumplimiento o la
              violación a las disposiciones de los presentes términos y
              condiciones de uso de la App. Mediante la aceptación de los
              presentes términos y condiciones, el Usuario acepta liberar y
              sacar en paz y a salvo a Audi México respecto a cualquier
              reclamación, queja, demanda, denuncia o cualquier otra acusación
              ante cualquier tipo de autoridad, que sea interpuesta por
              cualquier tercero, con relación a la información relevada por los
              Usuarios, o por el mal uso de la App en contravención a lo
              dispuesto en los presentes términos y condiciones.
            </p>
            <ol start="6">
              <li>Derechos de Propiedad Industrial e Intelectual.</li>
            </ol>
            <p>
              Todo el contenido de la App es propiedad de AUDI MÉXICO, S.A. de
              C.V. (Audi México) y/o Audi AG y/o las empresas que conforman la
              Organización Mundial Volkswagen y/o aparece con la autorización de
              los titulares de los derechos correspondientes, por tanto los
              derechos sobre dicho contenido se encuentran protegidos por las
              leyes mexicanas aplicables y por el derecho internacional.
            </p>
            <p>
              Asimismo se encuentran protegidos los derechos sobre las palabras
              Volkswagen y Audi el (los) logotipo(s) que la identifican (no
              importando sus colores tipos de representaciones gráficas, forma,
              tamaños u otras características) y cualquier otro elemento que
              sirva para distinguir a los productos, siluetas, imágenes,
              servicios, marcas, lemas publicitarios, videos o cualesquiera
              otros elementos de las empresas de la Organización Mundial
              Volkswagen, que se encuentren contenidos actualmente o se inserten
              en el futuro. Ninguno de estos elementos puede ser utilizado sin
              el previo permiso por escrito de AUDI MÉXICO, S.A. de C.V, y/o
              AUDI AG quedando por tanto prohibida su reproducción,
              modificación, distribución, transmisión, re-publicación,
              exhibición o ejecución, así como cualquier otro uso del contenido
              de esta App en alguna otra página de Internet o en alguna otra red
              informática, incluso en la publicidad y anuncios en relación con
              la conformación de esta App.
            </p>
            <p>
              Queda prohibido al Usuario el intentar obtener información,
              mensajes, archivos de sonido y/o de imagen (incluyendo
              fotografías, grabaciones, videos, dibujos, software, etc.) o
              cualquier otro elemento de esta App empleando cualquier medio
              distinto a los puestos expresamente a disposición del Usuario para
              tal fin. Las descargas de cualquier tipo de elemento contenido en
              esta App (incluyendo cualquier programa conectado por hipertexto o
              ligas –links-) sólo están autorizadas para efectos de su
              aplicación en la visita de nuestra App, por lo que para cualquier
              uso distinto, el Usuario, quedará sujeto a las políticas, términos
              y condiciones, así como a las obligaciones establecidas por los
              titulares de los derechos sobre los documentos de descarga,
              liberando a Audi México de cualquier responsabilidad que se genere
              al respecto.
            </p>
            <p>
              De igual forma el Usuario deberá abstenerse de intentar realizar,
              o realizar cualquier tipo de adición, supresión, transformación o
              cualquier otra forma de alteración al contenido de esta App;
              asimismo el Usuario no deberá suprimir o alterar en forma alguna
              cualquier dato que identifique la titularidad de derechos sobre
              cualquier contenido de esta App.
            </p>
            <p>
              Cualquier uso no autorizado del contenido de esta App será
              violatorio de la Ley de la Propiedad Industrial, Ley Federal del
              Derecho de Autor, Leyes Civiles, Mercantiles, Penales y demás
              Leyes Nacionales o Internacionales aplicables.
            </p>
            <ol start="7">
              <li>Transmisión de información.</li>
            </ol>
            <p>
              Queda estrictamente prohibida la publicación y envío a este o de
              esta App, de todo tipo de publicación o información que no cumpla
              con los requisitos o sea violatoria de las disposiciones
              contenidas en los presentes términos y condiciones, especialmente,
              tratándose de publicación o información que puedan considerarse
              constitutivas de delitos que atenten contra la privacidad, la
              integridad física y moral de las personales, así como aquellas
              conductas que lesionen o conculquen derechos patrimoniales de
              terceras personas.
            </p>
            <p>
              Toda la información que se transmita a o desde esta App, deberá de
              ser veraz, exacta, comprobable y actualizada. Los Usuarios son
              responsables, ante Audi México y ante terceros, de la información
              compartida a través de esta App.
            </p>
            <ol start="8">
              <li>Responsabilidades.</li>
            </ol>
            <p>
              Audi México, preocupado por ofrecer una aplicación confiable y de
              calidad a todos sus Usuarios, procura revisar y actualizar de
              forma periódica los elementos que conforman el contenido de esta
              App, sin embargo, es pertinente informar al Usuario que además de
              las exclusiones de responsabilidad que se puedan mencionar
              específicamente en estos Términos y Condiciones, en forma
              enunciativa mas no limitativa, se encuentran las que se mencionan
              a continuación:
            </p>
            <p>
              Audi México no asume ninguna responsabilidad ni se hace
              responsable por el funcionamiento o cualesquiera daños y/o
              perjuicios que el Usuario pueda sufrir, derivado de la interacción
              de la App con su dispositivo móvil, ni acepta ninguna
              responsabilidad por cualquier daño o perjuicio derivado de
              presencia de virus, de programas malicioso o lesivos en los
              contenidos, ni por cualquier otro elemento que pudiera ser
              introducido por cualquier tercero violando los controles de
              seguridad establecidos en la App.
            </p>
            <p>
              La App ha sido revisada y aprobada para que funcione
              correctamente, sin embargo, no se garantiza la disponibilidad y
              continuidad total o parcial de esta App o las secciones que la
              integran. Por lo anterior, los Usuarios reconocen y aceptan que
              Audi México no se hace responsable por cualquier daño o perjuicio
              derivado de la falta de continuidad o disponibilidad de conexión a
              la App.
            </p>
            <p>
              Audi México no asume ninguna responsabilidad por cualquier daño o
              perjuicio derivado de:
            </p>
            <ul>
              <li>
                El uso inadecuado por parte de los Usuarios de esta App o
                respecto de los elementos que conforman su contenido.
              </li>
              <li>
                La falta de precisión, pertinencia, detalle del contenido de
                nuestra aplicación.
              </li>
              <li>
                Que esta aplicación no satisfaga las expectativas del Usuario o
                la información o los servicios y/o productos no le sean útiles
                para cualquier fin específico deseado por el Usuario
              </li>
              <li>
                El funcionamiento de cualquiera de los enlaces y/o ligas (links)
                contenidos en esta App y/o por la calidad, licitud, fiabilidad y
                utilidad de los productos, servicios, información o cualesquiera
                elementos de los contenidos en otros sitios webs y/o redes
                sociales y/o aplicaciones vinculadas desde esta App.
              </li>
              <li>
                Las modificaciones o eliminaciones que Audi México lleve a cabo
                respecto a los módulos que integran la App y al funcionamiento
                de los mismos.
              </li>
            </ul>
            <ol start="9">
              <li>Adicionales.</li>
            </ol>
            <p>
              Audi México se reserva expresamente el derecho de modificar,
              eliminar, actualizar o corregir en cualquier momento los presentes
              Términos y Condiciones, siendo aplicables a los Usuarios, la
              última versión publicada por Audi México al momento del uso de la
              App. Los Usuarios serán responsables de visitar y revisar los
              Términos y Condiciones aplicables y actuales cada vez que tengan
              acceso a la App, sin embargo, desde el momento de aceptación de
              los presentes Términos y Condiciones en su proceso de registro,
              aceptan y autorizan expresamente a Audi México a llevar a cabo las
              modificaciones, eliminación, actualizaciones o correcciones
              necesarias
            </p>
            <p>
              Los correos electrónicos y/o teléfonos y/o faxes y/o cualquier
              otro medio de comunicación que se haga referencia en esta App, no
              serán medios aceptados por Audi México o cualquier empresa de la
              Organización Mundial Volkswagen para la recepción por éstas de
              cualquier tipo de notificación judicial o extrajudicial, demandas,
              emplazamientos, requerimientos de autoridad o cualquier otro tipo
              de información distinta a la remitida para fines de consulta o
              realización de comentarios.
            </p>
            <p>
              El Usuario al hacer uso de esta app, acepta que cualquier
              controversia que se derive del uso e interacción con la misma, se
              ventilará ante las autoridades y los tribunales de la ciudad de
              Puebla, Puebla, renunciando expresamente a cualquier otra
              jurisdicción que pudiera corresponderles por razón de su domicilio
              presente o futuro.
            </p>
          </div>
        </div>
        <div className={this.state.eng ? "terms eng" : "d-none"}>
          <div className="back">
            <Link to="/">
              <img src={backRed} alt="Back" />
            </Link>
          </div>
          <div className="text-terms">
            <h4>Terms and conditions of use for “Audi Mexico App”.</h4>
            <p>
              Por favor lea los siguientes términos y condiciones que son
              aplicables al uso de la aplicación denominada Audi México (En lo
              sucesivo la App), que son aplicables para los empleados de AUDI
              MÉXICO S.A. de C.V. (en lo sucesivo Audi México). Al entrar y
              utilizar esta Aplicación móvil, Usted (el "Usuario") reconoce
              aceptar las condiciones y los términos aquí contenidos y declara
              expresamente la aceptación de lo siguiente:
            </p>
            <ol>
              <li>Estipulaciones Generales</li>
            </ol>
            <p>
              A través de la presente App, Audi México pone a disposición de sus
              empleados una herramienta de generación de contenido a través de
              los diversos módulos que la conforman, por lo que la información
              contenida en esta App, tendrá en todo momento un carácter
              meramente informativo y de consulta, por lo que los contenidos y/o
              criterios resultado de la interacción del Usuario con la App son
              exclusivamente para facilitar y fomentar la comunicación
              empresarial de Audi México con sus empleados.
            </p>
            <p>
              La información contenida en la App no podrá considerarse ni
              utilizarse como una prueba ante autoridades ni dársele un fin
              distinto al aquí estipulado.
            </p>
            <p>
              El registro por parte del Usuario de la app, es completamente
              voluntario y se encuentra restringido a aquellas personas que
              tienen carácter de empleados de Audi México, por lo que será
              negado el registro a toda persona que no cumpla con dicha
              condición.
            </p>
            <p>
              El Usuario deberá de facilitar la información requerida por la App
              a efecto de lograr su registro y proceder a la interacción con los
              distintos módulos que la conforman, para lo cual, dicha
              información deberá de ser real y coincidir con la información que
              se utilice en su carácter de empleados con Audi México.
            </p>
            <p>
              Durante el proceso de registro y la interacción con la App, el
              Usuario proporcionará Datos Personales a Audi México, por lo que
              el tratamiento de dichos datos personales se sujetará a lo
              establecido al efecto por el “Aviso de Privacidad para Aplicativo
              Móvil de Audi México”, el cual ha sido puesto a su disposición con
              anterioridad. Tal y como se menciona en dicho Aviso de Privacidad,
              es posible que en algunos módulos de la App se requiera la
              geo-localización de su dispositivo para el debido funcionamiento
              del correspondiente módulo; en este sentido para una
              geo-localización más adecuada, el Usuario deberá activar y
              autorizar los servicios de ubicación, que utilizan información
              como, por ejemplo, señales GPS, sensores de dispositivo, puntos de
              acceso Wi-Fi, identificadores de redes móviles, entre otros; que
              sirven para estimar la ubicación precisa. Si así lo desea, el
              Usuario puede, posteriormente, desactiva los servicios de
              geo-localización requeridos, teniendo en cuenta las limitaciones
              de uso de la App que ello podría implicar.
            </p>
            <ol start="2">
              <li>Obligaciones Generales de los Usuarios de la App</li>
            </ol>
            <p>
              Durante el uso de la App, el Usuario deberá siempre observar una
              conducta adecuada y de respeto, principalmente con otros Usuarios
              en aquellos módulos en los que se permita al Usuario hacer
              comentarios o publicaciones, debiendo de igual forma, en todo
              momento, abstenerse de publicar material que, enunciativa más no
              limitativamente, sea difamatorio, obsceno, pornográfico, ofensivo,
              profano, amenazante y/o violatorio de cualquier Ley, norma o
              reglamento aplicable, así como cualquier material que constituya o
              aliente cualquier conducta que pudiera considerarse como actividad
              criminar y/o provocar actos criminales o contrarios a la ley y/o
              que infrinja derechos de propiedad industrial o intelectual, así
              como derechos de autor.
            </p>
            <p>
              Los Usuarios deberán en todo momento proteger los intereses de
              Audi México, así como de cualquier otra empresa integrando del
              Grupo Mundial Volkswagen por lo que respecta a la confidencialidad
              de la información, en la inteligencia de lo anterior; el Usuario
              en caso de duda respecto a la clasificación de confidencialidad de
              la información que se encuentre disponible en la App, deberá de
              consultar a su superior jerárquico, a fin de resolver dicha duda y
              velar por la confidencialidad de la información.
            </p>
            <p>
              De igual forma, durante la interacción con la App, el Usuario
              deberá abstenerse de realizar cualesquiera de los siguientes
              actos:
            </p>
            <ul>
              <li>
                Publicar o enviar, de manera enunciativa más no limitativa,
                cualquier material que sea difamatorio, obsceno, pornográfico,
                ofensivo, profano, amenazante y/o violatorio de cualquier Ley,
                norma o reglamento aplicable, así como cualquier material que
                constituya o aliente cualquier conducta que pudiera considerarse
                como actividad criminar y/o provocar actos criminales o
                contrarios a la ley y/o que infrinja derechos de propiedad
                industrial o intelectual, así como derechos de autor, en
                detrimento de Audi México y/o cualquier otra empresa integrante
                del Grupo Mundial Volkswagen;
              </li>
              <li>
                Actuar en nombre y por cuenta de terceros o incluso en su
                carácter de representante de Audi México;
              </li>
              <li>
                Infringir derechos de Terceros, tal es el caso como la
                publicación de textos, imágenes, audios o videos propiedad de
                terceros, sin que se cuente con una autorización legal al
                respecto;
              </li>
              <li>
                Infringir cualquier lineamiento o política interno de Audi
                México por lo que respecta, principalmente, a la
                confidencialidad de la información y la protección de datos
                personales;
              </li>
              <li>
                Entregar sus datos personales, así como datos de registro y
                acceso (usuario y contraseña) a terceras personas que puedan
                hacer mal uso de la App;
              </li>
              <li>
                Realizar cualquier publicación de manera anónima bajo una
                identidad de usuario diferente al que le fue asignado en su
                carácter de empleado de Audi México;
              </li>
              <li>
                Transferir, divulgar, hacer accesible o de cualquier otra forma
                trasparentar a terceras personas, la información y contenido
                divulgado en la App;
              </li>
              <li>
                Realizar publicaciones en idiomas distintos a los autorizados
                por Audi México (Español, Alemán y/o Inglés);
              </li>
              <li>
                Utilizar la App como plataforma de publicidad, ya sea para la
                oferta de bienes y/o servicios adicionales y que no tengan
                relación directa con las funciones que desempeña al interior de
                Audi México en su carácter de empleado
              </li>
              <li>
                Divulgar información confidencial o personal de terceras
                personas que puedan poner en riesgo su integridad personal,
                física o moral;
              </li>
              <li>
                La utilización de tecnologías de la información para a
                generación de comentarios, respuestas o publicaciones
                automatizadas
              </li>
            </ul>
            <p>
              En adición a las obligaciones generales aquí establecidas, los
              Usuarios, al tener el carácter de empleados, deberán de cumplir
              con los reglamentos y lineamientos internos de Audi México.
            </p>
            <p>
              El Usuario entiende y acepta que la interacción en esta App, no
              deberá obstaculizar las obligaciones inherentes al desempeño de
              sus funciones al interior de Audi México y que el uso de dicha App
              en horarios laborales, puede ser causa justificada para el
              establecimiento de sanciones por parte de Audi México en atención
              a las disposiciones internas aplicables.
            </p>
            <ol start="3">
              <li>
                Obligaciones de confidencialidad y de protección de datos
                personales
              </li>
            </ol>
            <p>
              Durante la interacción con la App, los Usuarios entregarán y, a su
              vez, podrán tener acceso a datos de carácter personal que son
              considerados como confidenciales de conformidad con la Ley Federal
              de Protección de Datos Personales en Posesión de los Particulares,
              mismos que corresponden a la información personal de los empleados
              de Audi México y/o de cualquiera de las empresas integrantes del
              Grupo Mundial Volkswagen.
            </p>
            <p>
              Esta expresamente prohibido para los Usuarios, la divulgación,
              revelación, comunicación, transmisión, grabación, copiado o en
              cualquier forma reproducir sin autorización expresa y por escrito
              de Audi México, los datos personales a los que los Usuarios tengan
              acceso por medio de esta App.
            </p>
            <p>
              Los Usuarios que hagan uso de la aplicación, tienen conocimiento y
              otorgan su consentimiento, mediante la aceptación de los presentes
              términos y condiciones, a favor de Audi México, a efecto de llevar
              a cabo el tratamiento de sus datos personales para las finalidades
              que han sido previamente identificadas en el Aviso de Privacidad
              para Aplicativo Móvil de Audi México, mismo que fue puesto a su
              disposición con anterioridad.
            </p>
            <p>
              De igual forma, los Usuarios reconocen y aceptan, mediante la
              aceptación de los presentes términos y condiciones, que la
              violación o el incumplimiento a las disposiciones en materia de
              protección de datos personales, podrá ser sancionado por la Ley
              Federal de Protección de Datos Personales en Posesión de los
              Particulares, así como también, podrá ser sancionado de forma
              interna por Audi México, de conformidad con los lineamientos y
              reglamentos internos aplicables.
            </p>
            <ol start="4">
              <li>
                Autorización de gestión de datos laborales a través de la App
              </li>
            </ol>
            <p>
              El Usuario reconoce, acepta y autoriza que sus datos relacionados
              a procesos de Recursos Humanos, los cuales han sido recabados y
              tratados a lo largo de la relación laboral que mantiene con Audi
              México, se encuentren en la App como una forma fácil de acceso a
              dicha información por lo que autoriza a Audi México a que la
              información referida sea accesible a través de la App, asimismo
              reconoce que el tratamiento de dichos datos está sujeto al Aviso
              de Privacidad para empleados, el cual ha sido puesto a su
              disposición con anterioridad por Audi México.
            </p>
            <ol start="5">
              <li>
                Infracciones y sanciones a las condiciones de uso estipuladas.
              </li>
            </ol>
            <p>
              Audi México, a través del Administrador de la App, se reserva el
              derecho de suspender temporal o permanentemente a cualquier
              Usuario que infrinja las disposiciones de los presentes términos y
              condiciones, relativas al uso de la App, siempre y cuando existan
              pruebas contundentes o la sospecha razonable y fundada de la
              infracción cometida por el Usuarios.
            </p>
            <p>
              Independientemente de la conducta realizada por el Usuario, Audi
              México tendrá derecho a bloquear el acceso a toda o parte de la
              información y/o publicaciones de la App, en caso de que se reciba
              información que indique la presencia de cualquier acto o indicio
              de transgresión a los presentes términos y condiciones,
              independientemente de la sanciona administrativa a la que sea
              acreedor el Usuarios transgresor. De igual forma, en cualquier
              momento, Audi México estará facultado para censurar, eliminar,
              bloquear o incluso denunciar ante las autoridades competentes,
              cualquier tipo de comentario o publicación realizada por los
              Usuarios en la App.
            </p>
            <p>
              En caso de que la App de Audi México proporcione acceso a otros
              sitios web a través de enlaces, Audi México no acepta el contenido
              de terceros como propio y no asume responsabilidad por dicho
              contenido, así como por la veracidad o la exactitud de este. La
              única responsabilidad de Audi México respecto a enlaces que
              proporcionen información con contenido ilegal o no verificado,
              será eliminar el enlace, tan pronto tenga conocimiento de esto.
            </p>
            <p>
              Cada Usuario es responsable respecto a la información que otorgue
              mediante la interacción en la App, así como respecto a sus
              comentarios, publicaciones y comportamiento en la App, liberando
              en todo momento a Audi México de cualquier responsabilidad que
              pudiera llegar a surgir ante terceros por el incumplimiento o la
              violación a las disposiciones de los presentes términos y
              condiciones de uso de la App. Mediante la aceptación de los
              presentes términos y condiciones, el Usuario acepta liberar y
              sacar en paz y a salvo a Audi México respecto a cualquier
              reclamación, queja, demanda, denuncia o cualquier otra acusación
              ante cualquier tipo de autoridad, que sea interpuesta por
              cualquier tercero, con relación a la información relevada por los
              Usuarios, o por el mal uso de la App en contravención a lo
              dispuesto en los presentes términos y condiciones.
            </p>
            <ol start="6">
              <li>Derechos de Propiedad Industrial e Intelectual.</li>
            </ol>
            <p>
              Todo el contenido de la App es propiedad de AUDI MÉXICO, S.A. de
              C.V. (Audi México) y/o Audi AG y/o las empresas que conforman la
              Organización Mundial Volkswagen y/o aparece con la autorización de
              los titulares de los derechos correspondientes, por tanto los
              derechos sobre dicho contenido se encuentran protegidos por las
              leyes mexicanas aplicables y por el derecho internacional.
            </p>
            <p>
              Asimismo se encuentran protegidos los derechos sobre las palabras
              Volkswagen y Audi el (los) logotipo(s) que la identifican (no
              importando sus colores tipos de representaciones gráficas, forma,
              tamaños u otras características) y cualquier otro elemento que
              sirva para distinguir a los productos, siluetas, imágenes,
              servicios, marcas, lemas publicitarios, videos o cualesquiera
              otros elementos de las empresas de la Organización Mundial
              Volkswagen, que se encuentren contenidos actualmente o se inserten
              en el futuro. Ninguno de estos elementos puede ser utilizado sin
              el previo permiso por escrito de AUDI MÉXICO, S.A. de C.V, y/o
              AUDI AG quedando por tanto prohibida su reproducción,
              modificación, distribución, transmisión, re-publicación,
              exhibición o ejecución, así como cualquier otro uso del contenido
              de esta App en alguna otra página de Internet o en alguna otra red
              informática, incluso en la publicidad y anuncios en relación con
              la conformación de esta App.
            </p>
            <p>
              Queda prohibido al Usuario el intentar obtener información,
              mensajes, archivos de sonido y/o de imagen (incluyendo
              fotografías, grabaciones, videos, dibujos, software, etc.) o
              cualquier otro elemento de esta App empleando cualquier medio
              distinto a los puestos expresamente a disposición del Usuario para
              tal fin. Las descargas de cualquier tipo de elemento contenido en
              esta App (incluyendo cualquier programa conectado por hipertexto o
              ligas –links-) sólo están autorizadas para efectos de su
              aplicación en la visita de nuestra App, por lo que para cualquier
              uso distinto, el Usuario, quedará sujeto a las políticas, términos
              y condiciones, así como a las obligaciones establecidas por los
              titulares de los derechos sobre los documentos de descarga,
              liberando a Audi México de cualquier responsabilidad que se genere
              al respecto.
            </p>
            <p>
              De igual forma el Usuario deberá abstenerse de intentar realizar,
              o realizar cualquier tipo de adición, supresión, transformación o
              cualquier otra forma de alteración al contenido de esta App;
              asimismo el Usuario no deberá suprimir o alterar en forma alguna
              cualquier dato que identifique la titularidad de derechos sobre
              cualquier contenido de esta App.
            </p>
            <p>
              Cualquier uso no autorizado del contenido de esta App será
              violatorio de la Ley de la Propiedad Industrial, Ley Federal del
              Derecho de Autor, Leyes Civiles, Mercantiles, Penales y demás
              Leyes Nacionales o Internacionales aplicables.
            </p>
            <ol start="7">
              <li>Transmisión de información.</li>
            </ol>
            <p>
              Queda estrictamente prohibida la publicación y envío a este o de
              esta App, de todo tipo de publicación o información que no cumpla
              con los requisitos o sea violatoria de las disposiciones
              contenidas en los presentes términos y condiciones, especialmente,
              tratándose de publicación o información que puedan considerarse
              constitutivas de delitos que atenten contra la privacidad, la
              integridad física y moral de las personales, así como aquellas
              conductas que lesionen o conculquen derechos patrimoniales de
              terceras personas.
            </p>
            <p>
              Toda la información que se transmita a o desde esta App, deberá de
              ser veraz, exacta, comprobable y actualizada. Los Usuarios son
              responsables, ante Audi México y ante terceros, de la información
              compartida a través de esta App.
            </p>
            <ol start="8">
              <li>Responsabilidades.</li>
            </ol>
            <p>
              Audi México, preocupado por ofrecer una aplicación confiable y de
              calidad a todos sus Usuarios, procura revisar y actualizar de
              forma periódica los elementos que conforman el contenido de esta
              App, sin embargo, es pertinente informar al Usuario que además de
              las exclusiones de responsabilidad que se puedan mencionar
              específicamente en estos Términos y Condiciones, en forma
              enunciativa mas no limitativa, se encuentran las que se mencionan
              a continuación:
            </p>
            <p>
              Audi México no asume ninguna responsabilidad ni se hace
              responsable por el funcionamiento o cualesquiera daños y/o
              perjuicios que el Usuario pueda sufrir, derivado de la interacción
              de la App con su dispositivo móvil, ni acepta ninguna
              responsabilidad por cualquier daño o perjuicio derivado de
              presencia de virus, de programas malicioso o lesivos en los
              contenidos, ni por cualquier otro elemento que pudiera ser
              introducido por cualquier tercero violando los controles de
              seguridad establecidos en la App.
            </p>
            <p>
              La App ha sido revisada y aprobada para que funcione
              correctamente, sin embargo, no se garantiza la disponibilidad y
              continuidad total o parcial de esta App o las secciones que la
              integran. Por lo anterior, los Usuarios reconocen y aceptan que
              Audi México no se hace responsable por cualquier daño o perjuicio
              derivado de la falta de continuidad o disponibilidad de conexión a
              la App.
            </p>
            <p>
              Audi México no asume ninguna responsabilidad por cualquier daño o
              perjuicio derivado de:
            </p>
            <ul>
              <li>
                El uso inadecuado por parte de los Usuarios de esta App o
                respecto de los elementos que conforman su contenido.
              </li>
              <li>
                La falta de precisión, pertinencia, detalle del contenido de
                nuestra aplicación.
              </li>
              <li>
                Que esta aplicación no satisfaga las expectativas del Usuario o
                la información o los servicios y/o productos no le sean útiles
                para cualquier fin específico deseado por el Usuario
              </li>
              <li>
                El funcionamiento de cualquiera de los enlaces y/o ligas (links)
                contenidos en esta App y/o por la calidad, licitud, fiabilidad y
                utilidad de los productos, servicios, información o cualesquiera
                elementos de los contenidos en otros sitios webs y/o redes
                sociales y/o aplicaciones vinculadas desde esta App.
              </li>
              <li>
                Las modificaciones o eliminaciones que Audi México lleve a cabo
                respecto a los módulos que integran la App y al funcionamiento
                de los mismos.
              </li>
            </ul>
            <ol start="9">
              <li>Adicionales.</li>
            </ol>
            <p>
              Audi México se reserva expresamente el derecho de modificar,
              eliminar, actualizar o corregir en cualquier momento los presentes
              Términos y Condiciones, siendo aplicables a los Usuarios, la
              última versión publicada por Audi México al momento del uso de la
              App. Los Usuarios serán responsables de visitar y revisar los
              Términos y Condiciones aplicables y actuales cada vez que tengan
              acceso a la App, sin embargo, desde el momento de aceptación de
              los presentes Términos y Condiciones en su proceso de registro,
              aceptan y autorizan expresamente a Audi México a llevar a cabo las
              modificaciones, eliminación, actualizaciones o correcciones
              necesarias
            </p>
            <p>
              Los correos electrónicos y/o teléfonos y/o faxes y/o cualquier
              otro medio de comunicación que se haga referencia en esta App, no
              serán medios aceptados por Audi México o cualquier empresa de la
              Organización Mundial Volkswagen para la recepción por éstas de
              cualquier tipo de notificación judicial o extrajudicial, demandas,
              emplazamientos, requerimientos de autoridad o cualquier otro tipo
              de información distinta a la remitida para fines de consulta o
              realización de comentarios.
            </p>
            <p>
              El Usuario al hacer uso de esta app, acepta que cualquier
              controversia que se derive del uso e interacción con la misma, se
              ventilará ante las autoridades y los tribunales de la ciudad de
              Puebla, Puebla, renunciando expresamente a cualquier otra
              jurisdicción que pudiera corresponderles por razón de su domicilio
              presente o futuro.
            </p>
          </div>
        </div>
        <div className="borde"></div>

        <Body esp={this.state.esp} eng={this.state.eng} />
        <Footer
          esp={this.state.esp}
          eng={this.state.eng}
          changeLanguage={this.changeLanguage}
        />
      </div>
    );
  }
}

export default Conditions;
