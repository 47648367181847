import React, { Component } from "react";
import audi from "../assets/img/logo_AMAP.png";
import { runInThisContext } from "vm";

export class Body extends Component {
  render() {
    return (
      <div>
        <div className={this.props.esp ? "body" : "d-none"}>
          <div className="imagenAudi">
            <img src={audi} alt="Audi" />
          </div>
          <div className="texto-body">
            <h1>App Audi México</h1>
          </div>
        </div>
        <div className={this.props.eng ? "body eng" : "d-none"}>
          <div className="imagenAudi">
            <img src={audi} alt="Audi" />
          </div>
          <div className="texto-body">
            <h1>Audi México App</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default Body;
