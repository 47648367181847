import React, { Component } from "react";
import Footer from "../layout/Footer";
import Body from "../layout/Body";
import Navbar from "./reutilizables/Navbar/Navbar";
import backRed from "../assets/img/arrow_back_red.png";
import { Link } from "react-router-dom";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import vector from "../assets/img/Vector.png";
export class Questions extends Component {
  constructor() {
    super();
    this.state = {
      esp: true,
      eng: false
    };
  }
  componentDidMount() {
    document.addEventListener("scroll", this.listenScrollEvent);
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    const eng = localStorage.getItem("eng");

    if (eng === "true") {
      this.setState({
        esp: false,
        eng: true
      });
    }
  }
  changeLanguage = e => {
    const value = e.target.value;
    if (value === "esp") {
      localStorage.setItem("eng", false);
      this.setState({
        esp: true,
        eng: false
      });
    } else {
      localStorage.setItem("eng", true);
      this.setState({
        esp: false,
        eng: true
      });
    }
  };
  componentWillUnmount() {
    document.removeEventListener("scroll", this.listenScrollEvent);
  }
  render() {
    return (
      <div>
        <Navbar
          esp={this.state.esp}
          eng={this.state.eng}
          changeLanguage={this.changeLanguage}
        />
        <div className={this.state.esp ? "faq esp" : "d-none"}>
          <div className="back">
            <Link to="/">
              <img src={backRed} alt="Back" />
            </Link>
          </div>
          <div className="acordion">
            <h4>Preguntas frecuentes</h4>
            <Accordion atomic={true}>
              <img className="vector" src={vector} alt="" />
              <AccordionItem title="¿Cómo consigo la aplicación Audi Mexico App?">
                <p className="p1Regular">
                  Es necesario descargarla directamente de las tiendas Apple
                  Store y Google Playstore.
                </p>
              </AccordionItem>
            </Accordion>
            <Accordion atomic={true}>
              <img className="vector" src={vector} alt="" />
              <AccordionItem title="¿La aplicación está disponible para el público en general?">
                <p className="p1Regular">Sí.</p>
              </AccordionItem>
            </Accordion>
            <Accordion atomic={true}>
              <img className="vector" src={vector} alt="" />
              <AccordionItem title="¿Para qué sistemas operativos está disponible la aplicación?">
                <p className="p1Regular">
                  Para Android 6 Marshmelow o superior, disponible en Google
                  Playstore y para iOS 11 o superior, disponible en Apple Store.
                </p>
              </AccordionItem>
            </Accordion>
            <Accordion atomic={true}>
              <img className="vector" src={vector} alt="" />
              <AccordionItem title="¿Qué tan frecuentemente se actualiza la aplicación?">
                <p className="p1Regular">
                  Audi México App será actualizada conforme sea necesario. Sin
                  embargo, el contenido se actualiza frecuentemente de acuerdo a
                  la relevancia del mismo.
                </p>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        <div className={this.state.eng ? "faq eng" : "d-none"}>
          <div className="back">
            <Link to="/">
              <img src={backRed} alt="Back" />
            </Link>
          </div>
          <div className="acordion">
            <h4>FAQ</h4>
            <Accordion atomic={true}>
              <img className="vector" src={vector} alt="" />
              <AccordionItem title="How can I get Audi Mexico App?">
                <p className="p1Regular">
                  Download it directly from Apple Store or Google Playstore.
                </p>
              </AccordionItem>
            </Accordion>
            <Accordion atomic={true}>
              <img className="vector" src={vector} alt="" />
              <AccordionItem title="Is the app available for the general public?">
                <p className="p1Regular">Yes.</p>
              </AccordionItem>
            </Accordion>
            <Accordion atomic={true}>
              <img className="vector" src={vector} alt="" />
              <AccordionItem title="What operating sistems is the app compatible with?">
                <p className="p1Regular">
                  Android 6 Marshmelow or above, available in Google Playstore
                  and for iOS 11 or above, available in Apple Store.
                </p>
              </AccordionItem>
            </Accordion>
            <Accordion atomic={true}>
              <img className="vector" src={vector} alt="" />
              <AccordionItem title="How frequently is the app updated?">
                <p className="p1Regular">
                  The app will be updated as required. However, the app’s
                  content is updated according to relevance.
                </p>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="borde"></div>

        <Body esp={this.state.esp} eng={this.state.eng} />
        <Footer
          esp={this.state.esp}
          eng={this.state.eng}
          changeLanguage={this.changeLanguage}
        />
      </div>
    );
  }
}

export default Questions;
