import React, { Component } from "react";
import "../../../assets/css/responsive.css";
import "../../../assets/css/main.css";
import logo from "../../../assets/img/ArosAudi.png";
export class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className="navbar">
          <div className="logo">
            <img src={logo} alt="Audi Logo" />
          </div>
          <div className="select" onChange={this.props.changeLanguage}>
            <select name="" id="lang" defaultValue="esp">
              <option value="esp" selected={this.props.esp ? true : false}>
                Español
              </option>
              <option value="eng" selected={this.props.eng ? true : false}>
                English
              </option>
            </select>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
