import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Footer extends Component {
  render() {
    return (
      <div>
        <footer>
          <div className={this.props.esp ? 'esp' : 'd-none'}>
            <div className='links-1'>
              <h3>Legal</h3>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: '/terms',
                      state: { esp: true, eng: false }
                    }}
                  >
                    Términos y condiciones
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: '/privacy',
                      state: { esp: true, eng: false }
                    }}
                  >
                    Aviso de privacidad
                  </Link>
                </li>
              </ul>
            </div>

            <div className='links-2'>
              <h3>Soporte</h3>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: '/faq',
                      state: { esp: true, eng: false }
                    }}
                  >
                    Preguntas frecuentes
                  </Link>
                </li>
                <li>
                  <a
                    href='https://about-2019.typeform.com/to/oNZrLb'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    Contáctanos
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className={this.props.eng ? 'eng' : 'd-none'}>
            <div className='links-1'>
              <h3>Legal</h3>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: '/terms',
                      state: { esp: false, eng: true }
                    }}
                  >
                    Terms and conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: '/privacy',
                      state: { esp: false, eng: true }
                    }}
                  >
                    Privacy
                  </Link>
                </li>
              </ul>
            </div>

            <div className='links-2'>
              <h3>Support</h3>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: '/faq',
                      state: { esp: false, eng: true }
                    }}
                  >
                    FAQ
                  </Link>
                </li>
                <li>
                  {' '}
                  <a
                    href='https://about-2019.typeform.com/to/IaW7su'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='select-2'>
            <select
              name=''
              id='lang2'
              defaultValue='esp'
              onChange={this.props.changeLanguage}
            >
              <option value='esp' selected={this.props.esp ? true : false}>
                Español
              </option>
              <option value='eng' selected={this.props.eng ? true : false}>
                English
              </option>
            </select>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
