import React, { Component } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Body from "../layout/Body";
import Navbar from "./reutilizables/Navbar/Navbar";
export class Home extends Component {
  constructor() {
    super();
    this.state = {
      esp: true,
      eng: false
    };
  }
  changeLanguage = e => {
    const value = e.target.value;
    if (value === "esp") {
      localStorage.setItem("eng", false);
      this.setState({
        esp: true,
        eng: false
      });
    } else {
      localStorage.setItem("eng", true);
      this.setState({
        esp: false,
        eng: true
      });
    }
  };
  componentDidMount() {
    document.addEventListener("scroll", this.listenScrollEvent);
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    const eng = localStorage.getItem("eng");

    if (eng === "true") {
      this.setState({
        esp: false,
        eng: true
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.listenScrollEvent);
  }
  render() {
    return (
      <div>
        <Navbar
          changeLanguage={this.changeLanguage}
          esp={this.state.esp}
          eng={this.state.eng}
        />
        <Header esp={this.state.esp} eng={this.state.eng} />
        <Body esp={this.state.esp} eng={this.state.eng} />
        <Footer
          esp={this.state.esp}
          eng={this.state.eng}
          changeLanguage={this.changeLanguage}
        />
      </div>
    );
  }
}

export default Home;
